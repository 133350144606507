import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { Link } from 'gatsby';


const Footer = ({ data }) => {



      return (


            <>


                  {/* FOOTER */}
                  <div className="c-footer">
                        <div className="o-wrapper">
                              <div className="c-footer__logo-holder">
                                    <div className="c-footer__logo-width">
                                          <a href="home.html" className="c-footer__logo"><span className="u-hidden-for-seo">CrediOfertas</span></a>
                                    </div>
                              </div>{/*
                */}<div className="c-footer__atention">
                                    <span className="c-footer__atention-text">Atención al Cliente</span>
                                    <a href="tel:+59826056449" className="c-footer__phone-number"><span className="c-footer__phone-icon" />2605 6449</a>
                                    <a href="mailto:contacto@crediofertas.com.uy" className="c-footer__email">contacto@crediofertas.com.uy</a>
                              </div>
                              <p className="c-footer__text">

                                    <RichText render={data.text} />

                              </p>
                              <ul className="c-footer__links">
                                    <li>
                                          <Link to={`/condiciones`} className="c-footer__link" >Términos y condiciones</Link> 
                                    </li>
                              </ul>
                        </div>
                  </div>{/* .c-footer */}
                  <div className="c-footer__disclaimer">
                        <div className="c-footer__disclaimer-holder o-wrapper">
                              <div className="c-footer__copyright">
                                    <p className="c-footer__copyright-text">Copyright © {new Date().getFullYear()} CrediOfertas.</p> <p className="c-footer__copyright-text">Todos los Derechos Reservados. - Montevideo, Uruguay.</p>
                              </div>
                              {' '}
                              <ul className="c-credits">
                                    <li className="c-credits__item">
                                          Diseño:
                <a title="Nimbo" className="c-credits__link" target="_blank" rel="noreferrer" href="https://www.nimbo.com.uy/">
                                                <span className="u-hidden-for-seo">Nimbo</span>
                                                <span className="c-credits__img c-credits__img--nimbo" />
                                          </a>
                                    </li>
                                    {' '}
                                    <li className="c-credits__item">
                                          Desarrollo Web:
                <a title="Bloque - Estudio de diseño y desarrollo web" className="c-credits__link" target="_blank" rel="noreferrer" href="https://www.bloque.com.uy/">
                                                <span className="u-hidden-for-seo">Bloque</span>
                                                <span className="c-credits__img c-credits__img--bloque" />
                                          </a>
                                    </li>
                                    {' '}
                              </ul>{/* .c-credits */}
                        </div>{/* .c-footer__disclaimer-holder */}
                  </div>{/* .c-footer__disclaimer */}

            </>
      )
}



Footer.propTypes = {
      data: PropTypes.object
}


export default Footer;
