import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { Link } from 'gatsby';




const Header = ({ data }) => {

      const { pathname } = useLocation();

      let isHome = pathname == "/" ? true : false;
      let isSolicitud = pathname == "/solicitar-prestamo" ? true : false;
      let isBlog = pathname == "/articulos" ? true : false;

      return (


            <>
                  {/* HEADER */}
                  <header id="c-header" className={`c-header ${isHome ? "not-fixed" : ""}  ${isSolicitud ? "c-header--solicitud" : ""}`}>
                        <div className="c-header__holder">
                              <div className="c-header__wrapper o-align-middle o-wrapper">{/*
                    */}<div className="o-align-middle__aligner">
                                          <h1 className="c-header__h1">

                                                {isHome &&

                                                      <button className="c-header__logo">
                                                            <span className="u-hidden-for-seo">{data.title}</span>
                                                      </button>


                                                }

                                                {!isHome &&

                                                      <Link to={"/"} className="c-header__logo">
                                                            <span className="u-hidden-for-seo">{data.title}</span>
                                                      </Link>


                                                }
                                          </h1>
                                          <button type="button" className="c-header__nav-btn js-show-nav" title="Ver/ocultar menú"><span className="c-header__hamburger" /></button>
                                          <div className="c-header__nav-mobile js-nav">
                                                <nav className="c-header__nav">
                                                      <h2 className="u-hidden-for-seo">Navegación</h2>
                                                      <ul>


                                                            {isHome &&
                                                             

                                                                  <li className={`c-nav__item ${isHome ? 'is-active' : ''}`}>
                                                                        <button className="c-btn c-btn-menu">Inicio</button>
                                                                  </li>


                                                            }

                                                            {!isHome &&

                                                                  <li className={`c-nav__item ${isHome ? 'is-active' : ''}`}>
                                                                        <Link to={`/`} className="c-btn c-btn-menu">Inicio</Link>
                                                                  </li>

                                                            }


                                                            <li className={`c-nav__item ${isBlog ? 'is-active' : ''}`}>
                                                                  <Link to={`/articulos`} className="c-btn c-btn-menu">Blog</Link>
                                                            </li>

                                                            <li className={`c-nav__item ${isSolicitud ? 'is-active' : ''}`}>
                                                                  <Link to={`/solicitar-prestamo`} className="c-btn c-btn-menu c-btn--filled">Buscar préstamo</Link>
                                                            </li>


                                                      </ul>{/* .c-nav__list */}
                                                </nav>{/* .c-header__nav */}
                                                <ul className="c-nav-mobile__links">
                                                      <Link to={`/condiciones`} className="c-nav-mobile__link">Términos y condiciones</Link>
                                                </ul>
                                          </div>{/* .c-header__nav-mobile */}
                                    </div>{/* .o-align-middle__aligner */}
                              </div>{/* .c-header__wrapper */}
                        </div>{/* .c-header__holder */}
                  </header>{/* .c-header */}

                  {isHome &&
                        <div className="js-header-waypoints" id="c-header-waypoint" />
                  }

            </>
      )
}



Header.propTypes = {
      data: PropTypes.object
}


export default Header;
